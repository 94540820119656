<template>
  <div>
    <label
      class="text-left block"
      :class="
        isBig ? 'font-sans text-sm md:text-base font-medium' : 'list-label'
      "
    >
      <span v-if="label" class="ml-4">
        {{ required ? `${$t(label)}*` : $t(label) }}
      </span>
      <CommonTooltip v-if="label && tooltip" :text="$t(tooltip)" class="ml-1">
        <CommonFontAwesomeIcon
          class="text-gray-400"
          icon="fa-solid fa-circle-info"
        />
      </CommonTooltip>
      <input
        :value="modelValue"
        class="w-full flex-grow appearance-none border px-4 !font-medium text-black leading-tight article-label placeholder-opacity-50 focus-within:outline-none focus-within:ring"
        :class="{
          border: validity !== 'valid',
          'border-red': validity !== 'valid',
          'rounded-md': isMm(),
          'rounded-full': !isMm(),
          'mt-2 py-4': isBig,
          'mt-1 py-2': !isBig,
          'bg-gray-200': disabled,
        }"
        v-bind="$attrs"
        :data-testid="props.dataTestid"
        :disabled="disabled ?? false"
        :required="required"
        :minlength="minlength"
        :maxlength="maxlength"
        :placeholder="placeholder ? $t(placeholder) : undefined"
        @input="handleInput"
        @blur="validateInput"
        ref="mainInput"
      />
    </label>
    <i18n-t
      v-if="validity !== 'valid'"
      :keypath="'formErrors.' + validity"
      tag="p"
      scope="global"
      class="list-label text-red text-right mt-1"
    >
      <template #field>
        {{ $t(placeholder).trim() }}
      </template>
      <template #fieldLowerCase>
        {{ $t(placeholder).toLowerCase().trim() }}
      </template>
      <template #minlength>
        {{ minlength }}
      </template>
      <template #maxlength>
        {{ maxlength }}
      </template>
    </i18n-t>
  </div>
</template>

<script setup lang="ts">
import { InputFieldInjectionKey } from '~/typesManual/InjectKeys'

export type Validity =
  | 'valid'
  | 'badInput'
  | 'customError'
  | 'patternMismatch'
  | 'rangeOverflow'
  | 'rangeUnderflow'
  | 'stepMismatch'
  | 'tooLong'
  | 'tooShort'
  | 'typeMismatch'
  | 'valueMissing'

const props = withDefaults(
  defineProps<{
    modelValue: string
    placeholder?: string
    required?: boolean
    minlength?: number
    maxlength?: number
    label?: string
    name?: string
    dataTestid?: string
    tooltip?: string
    isBig?: boolean
    disabled?: boolean
  }>(),
  {
    placeholder: '',
  }
)

const mainInput = ref<HTMLInputElement>()

// We use the provide/inject pattern in order to prevent a bunch of defineExpose, all the way up the tree.
// We inject a function that takes the input reference as a value. In any parent element, we can then get
// this value by providing a function with the same key
const fieldSetter = inject(InputFieldInjectionKey, () => {
  // Do nothing
})
onMounted(() => {
  if (!mainInput.value) {
    return
  }
  fieldSetter(mainInput.value, props.name)
})

const validity = ref<Validity>('valid')

function validateInput(event: Event) {
  const target = event.target as HTMLInputElement
  const validityArray = Object.keys(
    Object.getPrototypeOf(target.validity)
  ) as Validity[]
  validity.value = validityArray.filter((key) => target?.validity[key])[0]
}

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.value)
}
</script>
